export const messages = [
  {
    title: 'Высылаю вам акты',
    time: '14:30',
    text: 'Во вложении акты за прошлый месяц. Прощу посмотреть подробнее, в чем там ошибка',
    textSide: 'Во вложении акты за прошлый месяц. Прощу посмотреть подробнее, в чем там ошибка',
    from: {
      email: 'От кого: ***enov.viktor@email.com',
      date: '14:30  12 января 2022',
    },
    spam: false,
    spamActive: false,
    url: 'acts',
  },

  {
    title: 'Скоро отпуск!',
    text: 'Горячие предложения по турам в турцию на это лето',
    textSide: 'Горячие предложения по турам в турцию на это лето',
    time: '12:15',
    from: {
      email: 'От кого: ***avia@travel.com',
      date: '12:15  12 января 2022',
    },
    spam: false,
    spamActive: false,
    url: 'vacation',
  },

  {
    title: 'Выгодное предложение',
    text: 'Выгодное предложение по вкладам в нашм Банке.',
    textSide: 'Выгодное предложение по вкладам в нашм Банке.',
    textSpam: 'Выгодное предложение по вкладам в <span class="spam">нашм</span> Банке.',
    time: '02:03',
    from: {
      email: 'От кого: ***hmoney*ankbank.com',
      date: '02:03  12 января 2022',
      emailSpam: 'От кого: <span class="spam">***hmoney*ankbank.com</span>',
      dateSpam: '<span class="spam">02:03</span> 12 января 2022',
    },
    spam: true,
    spamActive: false,
    url: 'benefit',
  },
]
