import { ref } from '@vue/composition-api'

import MailService from './components/mailService'

import { pushPopup } from '@/utils/pushPopup'
import {
  popupsArr, popupsArr2, popupsArr3, popupError,
} from './arrs'


export default {
  name: 'step1',
  components: {
    MailService,
  },
  setup(_, { emit }) {
    const mailService = ref(false)
    const active = ref(true)
    const popups = ref([])
    // const disabled = ref(false)
    // const start = ref(false)

    const capsuleList = ref([
      {
        title: 'Обновления', img: 'cloud', currentNum: 0, key: 'updateSystem',
      },
      {
        title: 'Offices', img: 'office', currentNum: 0, key: 'updateOffice',
      },
      {
        title: 'Антивирус', img: 'antivirus', currentNum: 0, key: 'updateAntivirus',
      },
    ])

    const init = () => {
      pushPopup(popupsArr, popups.value)
    }

    init()

    const nextStep = (val) => {
      if (!val) return

      if (val === 'nextPopups2') {
        popups.value.splice(0, 1)
        pushPopup(popupsArr2, popups.value)
      }

      if (val.event === 'openMailService') {
        popups.value.at(-1).buttonTitle = undefined
        // start.value = true
        mailService.value = true
      }

      if (val === 'finalPopups') {
        popups.value = []
        // disabled.value = true
        // mailService.value = false
        pushPopup(popupsArr3, popups.value)
      }

      if (val.event === 'nextStep') {
        emit('nextStep', 2)
      }
    }

    const handleError = () => {
      pushPopup(popupError, popups.value)
    }

    return {
      mailService,
      active,
      popups,
      capsuleList,
      // start,
      // disabled,
      nextStep,
      handleError,
    }
  },
}
