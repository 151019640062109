import {
  ref,
} from '@vue/composition-api'

import { messages } from './arrs'
import Vacation from './components/vacation'
import Benefit from './components/benefit'
import Acts from './components/acts'

export default {
  name: 'mailService',
  props: {
    disabled: Boolean,
  },
  components: {
    Vacation,
    Benefit,
    Acts,
  },

  setup(propx, { emit }) {
    const active = ref(false)
    const spam = ref(null)
    const messagesProxy = ref(messages)

    let found = false

    const inSpam = (item) => {
      active.value = true
      spam.value = item
    }

    const nextStep = () => {
      emit('nextStep', 'finalPopups')
    }

    const compare = () => {
      messagesProxy.value.forEach((e) => {
        if (e.url === spam.value.url) {
          if (e.spam) {
            e.spamActive = true
            e.from.email = e.from.emailSpam ? e.from.emailSpam : e.from.email
            e.from.date = e.from.dateSpam ? e.from.dateSpam : e.from.date
            e.text = e.textSpam ? e.textSpam : e.text
            found = true
            nextStep()
          }
        }
      })
      if (!found) {
        emit('error')
      }
      active.value = false
    }

    return {
      spam,
      active,
      messagesProxy,
      inSpam,
      compare,
    }
  },
}
